import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Register from "./Auth/Register";
import Login from "./Auth/Login";
import Dashboard from "./pages/Dashboard";
import "./App.css";
const App = () => {
  return (
    <Router>
      <Routes>
        {/* <Route path="/api/auth/signup" element={<Register />} />
        <Route path="/login" element={<Login />} /> */}
        <Route path="/nmes-foot-massager" element={<Dashboard />} />
      </Routes>
    </Router>
  );
};

export default App;
