import React from "react";
import CustomerComparison from "./CustomerComparison";
import CommentsSection from "./CommentSection";
import Footer from "./Footer";

const PageComponent3 = () => {
  return (
    <div>
      <h3 className="font-sans mb  mt-7 text-3xl font-extrabold text-black">
        I Decided to Offer a{" "}
        <span className="inline text-3xl rounded-lg  text-black bg-yellow-700 px-1">
          Special Limited-Time Discount
        </span>{" "}
        and Let You Have Your Own NoroPulse™ NMES Foot Massager at
      </h3>
      <img
        className="mt-8 rounded-lg  mb-3 flex  lg:w-screen-xl xl:max-w-screen-xl 2xl:max-w-screen-xl"
        src={require("../assets/49.99.PNG")}
        alt=""
      />
      <p className=" text-lg mt-6 text-gray-700 font-semibold">
        That's <span className=" text-green-700 font-bold  ">just $49.99!</span>
      </p>
      <p className="underline text-lg mt-6 text-black font-bold">
        This is the lowest price we'll ever offer.
      </p>
      <p className=" text-lg mt-6 text-gray-700 font-semibold">
        And I'm only able to guarentee it for today.
      </p>
      <p className=" text-lg mt-6 text-gray-700 font-semibold">
        So if you are ready to{" "}
        <span className="font-bold text-black">
          take advantage of the best deal you'll ever get
        </span>
        ...
      </p>
      <p className=" text-lg mt-6 text-gray-700 font-semibold">
        And get your <span className="text-blue-800">NoroPulse™</span>
        NMES Foot Massager at a fraction of the price...
      </p>
      <p className=" text-lg mt-6 text-gray-700 font-semibold">
        Make sure to{" "}
        <span className="underline text-blue-700 font-bold">
          click the big button below
        </span>{" "}
        and grab it while you still can!
      </p>
      <p className=" text-lg mt-6 text-gray-700 font-semibold">
        As I said earlier, we manufactured a limited number of massagers in this
        batch. And we'are selling out faster than anyone could have anticipated.
      </p>
      <p className=" text-lg mt-6 text-gray-700 font-semibold">
        So it's only a matter of time before we run out completely.
      </p>
      <p className=" text-lg mt-6 text-gray-700 font-semibold">
        And when that happens... Well, you'll pretty much miss out.
      </p>
      <p className=" text-lg mt-6 text-gray-700 font-semibold">
        Not just that you'll have to wait until the next batch is manufactured -
        which can take weeks if not months...
      </p>
      <p className=" text-lg mt-6 text-gray-700 font-semibold">
        But you'll also find a new price that will be higher.
      </p>
      <p className=" text-lg mt-6 text-gray-700 font-semibold">
        Let me be super clear about it:
      </p>
      <p className="inline rounded-md px-1 bg-yellow-600 text-lg mt-6 text-black font-bold">
        You'll never be able to buy a{" "}
        <span className="text-black">NoroPulse™</span> NMES Foot Massager
        cheaper than today.
      </p>
      <p className=" text-lg mt-6 text-gray-700 font-semibold">
        This is the best deal you will ever get.
      </p>
      <p className=" text-lg mt-6 text-gray-700 font-semibold">
        And this may be the only time you see it.
      </p>
      <p className=" text-lg mt-6 text-gray-700 font-semibold">
        So without further ado, click below to place your order.
      </p>
      <div className=" w-fit items-center">
        <button
          onClick={() =>
            (window.location.href =
              "https://noropulse.com/products/ems-foot-massager-leg-electric-deep-reshaping-kneading-muscle-pain-relax-machine")
          }
          className="bg-[#35E0D0] mt-9 mx-auto py-4 hover:bg-[#0a9789] shadow-[#135f58] shadow-2xl px-3 font-bold text-black text-2xl rounded-lg max-w-screen-sm w-full"
        >
          GET 50% OFF NMES Foot Massager Now!
        </button>
      </div>
      <h3 className="font-sans mb  mt-9 text-3xl font-extrabold text-black">
        You Have <span className="underline">30 Days</span> to Test the Product,
        Completely{" "}
        <span className="inline rounded-md px-1 bg-yellow-600   text-black ">
          Risk-Free!
        </span>
      </h3>
      <img
        className="mt-8 rounded-lg  mb-3  lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
        src={require("../assets/30dayzz.png")}
        alt=""
      />

      <p class="text-lg mt-6 text-gray-700 mt-8 font-semibold">
        Yes, you heard me right.
      </p>
      <p class="text-lg mt-6 text-black font-bold my-4">
        I'm giving you a 30-days TRIAL period.
      </p>
      <p class="text-lg mt-6 text-gray-700 font-semibold">
        You have 30 full days to experiment with the{" "}
        <a href="#" class="text-blue-700 font-bold">
          <span className="text-blue-800">NoroPulse™</span> NMES Foot Massager
        </a>{" "}
        and see how well it all works.
      </p>
      <p class="text-lg mt-6 text-gray-700 font-semibold my-4">
        If it works as advertised, you are welcome to keep it and use it forever
        to reduce varicose veins and keep swelling & pain at bay.
      </p>
      <p class="text-lg mt-6 text-gray-700 font-semibold">
        But if for ANY reason you are not happy with your purchase...
      </p>
      <p class="text-lg mt-6 text-gray-700 font-semibold">
        And you feel like there are better solutions out there...
      </p>
      <p class="text-lg mt-6 text-gray-700 font-semibold my-4">
        Just let us know anytime in the next 30 days and{" "}
        <span class="font-bold text-black">you’ll get your money back</span>{" "}
        that same day, no questions asked.
      </p>
      <p class="text-lg mt-6 text-gray-700 font-semibold">
        It doesn’t matter if it’s 29 minutes or 29 days after the purchase...
      </p>
      <p class="text-lg mt-6 text-gray-700 font-semibold">
        Our team at NoroPulse™ will make sure that you only pay if you are 100%
        in love with the product. In any other case, it’s on us.
      </p>
      <p class="text-lg mt-6 text-gray-700 font-semibold my-4">Fair enough?</p>
      <p class="text-lg mt-6 text-gray-700 font-semibold">
        Oh, and no worries...
      </p>
      <p class="text-lg mt-6 text-gray-700 font-semibold">
        Our team is so easy to access, so there will be no hassle.
      </p>
      <p class="text-lg mt-6 text-gray-700 font-semibold my-4">
        You can email us at{" "}
        <a class="text-blue-700 font-bold">support@noropulse.com</a>.
      </p>

      <p class="text-lg  mt-6 text-gray-700 font-semibold my-4">
        Even if you just have questions about the product.
      </p>
      <p class="text-lg mt-6 text-gray-700 font-semibold">
        Our dedicated customer service team will get back to you WITHIN MINUTES.
        No matter when you call us, we’re available 24/7.
      </p>
      <p class="text-lg mt-6 text-gray-700 font-semibold">
        Now that's what I call a{" "}
        <span className="text-black font-bold">RISK-FREE offer.</span>
      </p>
      <p class="text-lg mt-6 text-gray-700 font-semibold">
        We made sure that from the moment you click that button below...
      </p>
      <p class="text-lg mt-6 text-gray-700 font-semibold">
        You, your time, and your money are{" "}
        <span className="font-bold text-black">protected and safe!</span>
      </p>
      <p class="text-lg mt-6 text-gray-700 font-semibold">
        There is literally ZERO risk involved today.{" "}
      </p>
      <h3 className="font-sans mb  mt-9 text-3xl font-extrabold text-black">
        Here’s What to Do Next
      </h3>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        <span class="font-bold text-black">Click that big green button</span>{" "}
        that says “
        <span class="font-bold text-black">GET YOURS 50% OFF NOW!</span>” - it
        will take you straight to our official encrypted website.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        And your discount code will be automatically applied.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        From there, you’ll go straight to the checkout page where you just need
        to enter your name, address, and credit card information, and select how
        many <span className="text-blue-800 red-700">NoroPulse™</span> Foot
        Massagers you want to order.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        <span class="font-bold text-black">
          Most people end up getting TWO or THREE{" "}
          <span className="text-blue-800">NoroPulse™</span> Foot Massagers.
        </span>
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        One, because it’s practical to have one in your bedroom, one at work,
        etc.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        Two, because it’s a great gift for your spouse. And it’s much more
        enjoyable when you can get the massage together, at the same time.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        And three, because the more you get,{" "}
        <span class="font-bold text-black">the more money you save</span>.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        That’s because we save a lot of money on shipping when we send bigger
        orders - and we are happy to share those savings with you.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        So if you know someone who could benefit from{" "}
        <a href="#" class="font-bold text-black">
          <span className="text-blue-800">NoroPulse™</span> NMES Foot Massager
        </a>{" "}
        - be it a friend, a family member, or a colleague at work...
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        Or if you simply want to get more than one for yourself, so you can have
        one in your bedroom, one in your car, one at work, etc.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        <span class="font-bold text-black">
          I suggest you take advantage of this one-time deal
        </span>{" "}
        and click the button below to get the NoroPulse NMES Foot Massager at
        the lowest price ever.
      </p>
      <div className=" w-fit items-center">
        <button
          onClick={() =>
            (window.location.href =
              "https://noropulse.com/products/ems-foot-massager-leg-electric-deep-reshaping-kneading-muscle-pain-relax-machine")
          }
          className="bg-[#35E0D0] mt-9 mx-auto py-4 hover:bg-[#0a9789] shadow-[#135f58] shadow-2xl px-3 font-bold text-black text-2xl rounded-lg max-w-screen-sm w-full"
        >
          GET 50% OFF NMES Foot Massager Now!
        </button>
      </div>
      <h3 className="font-sans mb  mt-9 text-3xl font-extrabold text-black">
        Remember, There Is ZERO Risk
      </h3>
      <div className="bg-green-50 justify-center items-center flex p-4 mt-4 max-w-xl lg:max-w-screen-xl rounded-lg mb-4">
        <ul className=" flex-row flex list-inside">
          <li className="text-black gap-4 mb-2 items-center flex-row flex text-lg font-semibold">
            <img src={require("../assets/30day.png")} alt="" />
          </li>
          <li className="gap-4 text-black items-center flex-row flex text-lg font-semibold">
            <img src={require("../assets/1711461923065_2 (1).png")} alt="" />
          </li>
          <li className="gap-4 text-black items-center flex-row flex text-lg font-semibold">
            <img src={require("../assets/1711461935757_3.png")} alt="" />
          </li>
          <li className="gap-4 text-black items-center flex-row flex text-lg font-semibold">
            <img src={require("../assets/1711461945141_1.png")} alt="" />
          </li>
        </ul>
      </div>
      <p className="font-semibold mt-6 text-lg text-gray-700">
        The only risk you could possibly face…
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        <span className=" text-black">
          Is one of pain and regret if you miss out on the opportunity to get
          your{" "}
          <span className="text-blue-700 font-bold">
            <span class="text-blue-800">NoroPulse™</span> NMES Foot Massagers
          </span>
        </span>{" "}
        at this massive discount.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        Unfortunately, I know exactly what’s going to happen if you decide to
        let this opportunity pass. I’ve seen it so many times with other
        patients.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        And let me tell you, it’s NOT good.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        You’ll keep wasting time on compression socks, creams, and different
        pills that don’t address the real cause of the problem.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        You may experience some temporary relief now and then…
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        And maybe even fool yourself that you can live your life like this…
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        But the pain, swelling and varicose veins{" "}
        <span class="font-bold text-black">will only get worse</span>.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        I’m not saying this to SCARE you. I just want to WARN you.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        Because if left untreated, this can turn into a much bigger problem.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        That’s why the decision you make today will be one of the most important
        decisions of your life. So… What is it going to be?
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        <span className="font-bold text-black">Are you going to say “NO”</span>{" "}
        to this opportunity and take your chances?
      </p>
      <p class="mt-6 text-lg text-black font-bold">
        OR are you going to do the right thing… Order one, two, or three{" "}
        <span className="text-blue-700 font-bold">
          <span class="text-blue-800">NoroPulse™</span> NMES Foot Massagers
        </span>
        … And spend the next 90 days working on recovery?
      </p>
      <img
        className="mt-8 rounded-lg  mb-3  lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
        src={require("../assets/option.png")}
        alt=""
      />
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        Remember... This is not just about you.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        This is about your family - who will be worried about you constantly if
        you lose your mobility because you don’t want to try to get better.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        This is about your grandkids who will never get to play with you because
        your feet will be holding you back.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        This is about your partner who will have to get used to living alone
        while you are still there - because you will be a shell of yourself.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        You owe it to yourself and your loved ones to give it a shot.
      </p>
      <div className="bg-blue-100 p-4 mt-4 max-w-lg rounded-lg mb-4">
        <ul className=" list-inside">
          <li className="gap-4 text-black items-center flex-row flex text-lg font-semibold">
            <span className="flex justify-center  gap-3 flex-row">
              <img
                className="h-5 w-5"
                src={require("../assets/check.png")}
                alt=""
              />
              You can turn things around.
            </span>
          </li>
          <li className="gap-4 text-black items-center flex-row flex text-lg font-semibold">
            <span className="flex justify-center  gap-3 flex-row">
              <img
                className="h-5 w-5"
                src={require("../assets/check.png")}
                alt=""
              />
              You can get the relief that you’ve been looking for.
            </span>
          </li>
          <li className="gap-4 text-black items-center flex-row flex text-lg font-semibold">
            <span className="flex justify-center  gap-3 flex-row">
              <img
                className="h-5 w-5"
                src={require("../assets/check.png")}
                alt=""
              />
              You can get your old life back and enjoy the rest of your life.
            </span>
          </li>
        </ul>
      </div>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        Many of my patients have already done it.{" "}
        <span class="font-bold text-black">You can do it too.</span>
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        All you need is a little assistance from the finest varicose veins
        relief device that I know exists - our{" "}
        <span class="text-blue-500">NoroPulse™</span> NMES Foot Massager.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        So without further ado...
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        If you are ready to do the right thing...
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        Click that big green button below to order your{" "}
        <span class="text-blue-500">NoroPulse™</span> NMES Foot Massager.
      </p>
      <p class="mt-6 text-lg text-gray-700 font-semibold">
        And remember -{" "}
        <span class="font-bold text-black">
          If it doesn’t work as promised, you don’t pay.
        </span>
      </p>
      <CustomerComparison />
    </div>
  );
};

export default PageComponent3;
