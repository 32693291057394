import React from "react";
import usa from "../assets/united-states (1).png";
import CustomerReviews from "../components/CustomerReviews";
import ProfileSection from "../components/ProfileSection";
import PageComponent1 from "../components/PageComponent1";
import CommentsSection from "../components/CommentSection";

const Dashboard = () => {
  return (
    <div className=" sm:max-w-fit md:max-w-fit max-w-screen-xl overflow-y-auto  h-screen bottom-0">
      {/* Announce Bar */}
      <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-blue-50 py-2.5 sm:px-3.5 sm:before:flex-1">
        <div
          aria-hidden="true"
          className="absolute left-0 right-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        >
          <div
            style={{
              clipPath:
                "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
            }}
            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#80e1ff] to-[#9089fc] opacity-30"
          />
        </div>
        <marquee
          className="text-sm flex items-center leading-6 text-gray-900"
          direction="left"
          scrollamount="10"
        >
          <div className="flex items-center">
            <img
              src={require("../assets/sale-tag.png")}
              className="h-7 mr-4"
              alt=""
            />
            <span className="font-bold text-xl mr-6">
              ONE TIME 50% DISCOUNT ONLY TODAY!
            </span>
            <span className="font-bold text-xl mr-16">DON'T MISS OUT!</span>
            <img
              src={require("../assets/sale-tag.png")}
              className="h-7 mr-4"
              alt=""
            />
            <span className="font-bold text-xl mr-6">
              ONE TIME 50% DISCOUNT ONLY TODAY!
            </span>
            <span className="font-bold text-xl mr-16">DON'T MISS OUT!</span>
            <img
              src={require("../assets/sale-tag.png")}
              className="h-7 mr-4"
              alt=""
            />
            <span className="font-bold text-xl mr-6">
              ONE TIME 50% DISCOUNT ONLY TODAY!
            </span>
            <span className="font-bold text-xl mr-16">DON'T MISS OUT!</span>
            <img
              src={require("../assets/sale-tag.png")}
              className="h-7 mr-4"
              alt=""
            />
            <span className="font-bold text-xl mr-6">
              ONE TIME 50% DISCOUNT ONLY TODAY!
            </span>
            <span className="font-bold text-xl mr-16">DON'T MISS OUT!</span>
            <img
              src={require("../assets/sale-tag.png")}
              className="h-7 mr-4"
              alt=""
            />
            <span className="font-bold text-xl mr-6">
              ONE TIME 50% DISCOUNT ONLY TODAY!
            </span>
            <span className="font-bold text-xl mr-16">DON'T MISS OUT!</span>
            <img
              src={require("../assets/sale-tag.png")}
              className="h-7 mr-4"
              alt=""
            />
            <span className="font-bold text-xl mr-6">
              ONE TIME 50% DISCOUNT ONLY TODAY!
            </span>
            <span className="font-bold text-xl mr-16">DON'T MISS OUT!</span>
            <img
              src={require("../assets/sale-tag.png")}
              className="h-7 mr-4"
              alt=""
            />
            <span className="font-bold text-xl mr-6">
              ONE TIME 50% DISCOUNT ONLY TODAY!
            </span>
            <span className="font-bold text-xl mr-16">DON'T MISS OUT!</span>
            <img
              src={require("../assets/sale-tag.png")}
              className="h-7 mr-4"
              alt=""
            />
            <span className="font-bold text-xl mr-6">
              ONE TIME 50% DISCOUNT ONLY TODAY!
            </span>
            <span className="font-bold text-xl mr-16">DON'T MISS OUT!</span>
            <img
              src={require("../assets/sale-tag.png")}
              className="h-7 mr-4"
              alt=""
            />
            <span className="font-bold text-xl mr-6">
              ONE TIME 50% DISCOUNT ONLY TODAY!
            </span>
            <span className="font-bold text-xl mr-16">DON'T MISS OUT!</span>
            <img
              src={require("../assets/sale-tag.png")}
              className="h-7 mr-4"
              alt=""
            />
            <span className="font-bold text-xl mr-6">
              ONE TIME 50% DISCOUNT ONLY TODAY!
            </span>
            <span className="font-bold text-xl mr-16">DON'T MISS OUT!</span>
          </div>
        </marquee>
      </div>

      <div className="font-bold px-5 gap-2 text-white text-xl flex items-center justify-between w-screen h-16 bg-blue-100">
        <div className="font-sans text-lg text-black font-semibold">
          Advertorial
        </div>
        {/* <div className="font-bold text-2xl items-center justify-center text-white">
        neuroto
        </div> */}
        <img
          className="h-10 w-auto mx-5 flex"
          src={require("../assets/noropuls.png")}
          alt="usa"
        />
        <div className="items-center justify-center flex flex-row gap-2">
          <img className="h-10 w-10 flex" src={usa} alt="usa" />
          <div className="font-sans text-sm text-black font-normal">
            Trending in US
          </div>
        </div>
      </div>
      <div className=" mx-auto max-w-screen-2xl px-2">
        <div className="flex flex-col gap-3">
          <p className="opacity-40 mt-5  font-sans font-medium">
            Home &gt; Pain Relief &gt; NMES Foot Massager
          </p>
          <div className="flex flex-row">
            <div>
              <div className=" font-sans mt-9 font-normal text-3xl">
                Top Physical Therapist:
                <h1 className="font-sans mt-3 font-extrabold leading-19 text-4xl ">
                  This Is The Best Way To Reduce Varicose Veins and Relieve Leg
                  Swelling
                </h1>
                <div className="font-sans font-semibold mt-4 leading-10 text-xl">
                  <span className="inline mt-0 p-1 rounded-lg font-bold text-black bg-yellow-800 px-1">
                    If you struggle with varicose veins in your legs, calves,
                    feet, or ankles,
                  </span>
                  or if your legs feel heavy and achy, read this short article
                  right now before you do anything else.
                </div>
              </div>
              <div class="grid min-h-[100px] w-full  rounded-lg px-2 lg:overflow-visible">
                <div class="flex items-center gap-2 font-bold text-blue-gray-500">
                  4.7
                  <div class="inline-flex items-center">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-6 h-6 text-yellow-700 cursor-pointer"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-6 h-6 text-yellow-700 cursor-pointer"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-6 h-6 text-yellow-700 cursor-pointer"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-6 h-6 text-yellow-700 cursor-pointer"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 cursor-pointer text-blue-gray-500"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <p class="block font-sans text-base antialiased font-medium leading-relaxed text-blue-gray-500">
                    Based on 3875 Reviews
                  </p>
                </div>
              </div>
              <video
                className="w-screen-sm rounded-md"
                src={require("../assets/6607c1f773ed41c18d370e9208f61377 (1) (1) (1).mp4")}
                alt="gif1"
                autoPlay
                loop
                muted
                playsInline
              />
            </div>
            <div className=" flex-col hidden lg:block max-h-full bg-gray-300 text-blue-500  pt-4 px-1 rounded-md mx-2 items-center ">
              <div className="font-bold text-xl text-center">
                Finally Get Instant Temporary Relief From Varicose Veins In Your
                Legs, Calves, and Ankles
              </div>
              <div>
                <img
                  className="max-h-48 mt-5 max-w-48"
                  src={require("../assets/1714660676682_1711370022258_product.png")}
                  alt="gif2"
                />
                <button
                  onClick={() =>
                    (window.location.href =
                      "https://noropulse.com/products/ems-foot-massager-leg-electric-deep-reshaping-kneading-muscle-pain-relax-machine")
                  }
                  type="button"
                  class="text-black shadow-lg font-bold border-b-orange-800 border-b-2 border p-12 bg-yellow-700 hover:bg-yellow-900  focus:ring-gray-400 focus:bg-yellow-800  rounded-md text-xl px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900"
                >
                  <div className="flex stroke-transparent flex-row gap-2 items-center justify-center">
                    <img
                      className="h-8 max-w-full"
                      src={require("../assets/right-arrow.png")}
                      alt="arrow"
                    />
                    GET 50% OFF NOW!
                  </div>
                </button>
                <CustomerReviews />
              </div>
            </div>
          </div>
        </div>
        <ProfileSection />
        <PageComponent1 />
      </div>
      <div className="bg-blue-100 ">
        <CommentsSection />
      </div>
      <footer className="bg-[#0E1B4D] text-white py-8 pb-24">
        <div className="container mx-auto text-center">
          <h2 className="text-2xl font-semibold mb-4">
            Subscribe to our emails
          </h2>
          <p className="mb-6">
            Be the first to know about new collections and exclusive offers.
          </p>
          <form className="flex justify-center">
            <input
              type="email"
              placeholder="Email"
              className="px-4 py-2 rounded-l-lg text-gray-700 focus:outline-none"
            />
            <button
              type="submit"
              className="bg-gray-300 text-gray-900 px-4 py-2 rounded-r-lg hover:bg-gray-400"
            >
              →
            </button>
          </form>
          <div className="mt-8">
            <p className="mb-4">&copy; 2024, All Rights Reserved. NoroPulse™</p>
            <div className="flex justify-center items-center space-x-4 mb-4">
              <img
                src={require("../assets/payment_method.png")}
                alt="AMEX"
                className="h-5"
              />
            </div>
            <div className="flex justify-center space-x-4">
              <a href="#" className="hover:underline">
                Refund policy
              </a>
              <a href="#" className="hover:underline">
                Privacy policy
              </a>
              <a href="#" className="hover:underline">
                Terms of service
              </a>
              <a href="#" className="hover:underline">
                Shipping policy
              </a>
              <a href="#" className="hover:underline">
                Contact information
              </a>
            </div>
          </div>
        </div>
      </footer>
      <button
        className="fixed flex shadow-lg border border-black gap-3 flex-row items-center justify-center rounded-lg bottom-1 py-4 left-0 right-0 bg-[#35E0D0] hover:bg-[#18bdac] text-black text-center text-xl font-bold"
        onClick={() =>
          (window.location.href =
            "https://noropulse.com/products/ems-foot-massager-leg-electric-deep-reshaping-kneading-muscle-pain-relax-machine")
        }
      >
        <img
          className="h-8"
          src={require("../assets/fast-forward.png")}
          alt=""
        />
        <p className="stroke-black">GET YOURS 50% OFF NOW!</p>
        <img
          className="h-8"
          src={require("../assets/fast-forward2.png")}
          alt=""
        />
      </button>
    </div>
  );
};

export default Dashboard;
