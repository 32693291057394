import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";

const Register = () => {
  const [form, setForm] = useState({
    email: "",
    username: "",
    password: "",
    passwordConfirm: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    hotelName: "",
    remember: false,
  });
  const [errors, setErrors] = useState({});
  const [progress, setProgress] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm({
      ...form,
      // [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.firstName) newErrors.firstName = "Lütfen isim giriniz!";
    if (!form.lastName) newErrors.lastName = "Lütfen soyisim giriniz!";
    if (!form.email) newErrors.email = "Lütfen email giriniz!";
    if (form.email && !/\S+@\S+\.\S+/.test(form.email))
      newErrors.email = "Geçerli bir email giriniz!";
    if (!form.password) newErrors.password = "Lütfen şifre giriniz!";
    if (form.password !== form.passwordConfirm)
      newErrors.passwordConfirm = "Şifreler eşleşmiyor!";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setProgress(true);
      try {
        // console.log(e);
        const response = await axios.post(
          "http://localhost:3000/api/auth/signup",
          form
        );
        message.success(response.data.message);
        setProgress(false);
        // Optionally save the token to localStorage or context
        localStorage.setItem("token", response.data.token);
      } catch (error) {
        message.error(
          error.response?.data?.error || "Kayıt işlemi başarısız oldu."
        );
        setProgress(false);
      }
    }
  };

  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <div className="p-7 px-14 shadow-2xl rounded-3xl bg-white">
        <div className="relative flex flex-col text-gray-700 bg-transparent shadow-none rounded-xl bg-clip-border">
          <div className="flex flex-col mb-2 mt-5 items-center justify-center">
            <h4 className="block gap-5 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
              Kayıt Ol
            </h4>
            <p className="block mt-4 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
              Seni görmek güzel! Kayıt olmak için bilgilerini gir.
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            className="max-w-screen-lg mt-8 mb-2 w-80 sm:w-96"
          >
            <div className="flex flex-col gap-5 mb-5">
              <h6 className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                İsim
              </h6>
              <div className="relative mb-2 h-11 w-full min-w-[200px]">
                <input
                  name="firstName"
                  value={form.firstName}
                  onChange={handleChange}
                  placeholder="İsim"
                  className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-900"
                />
                {errors.firstName && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.firstName}
                  </p>
                )}
              </div>
              <h6 className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                Soyisim
              </h6>
              <div className="relative mb-2 h-11 w-full min-w-[200px]">
                <input
                  name="lastName"
                  value={form.lastName}
                  onChange={handleChange}
                  placeholder="Soyisim"
                  className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-900"
                />
                {errors.lastName && (
                  <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>
                )}
              </div>
              <h6 className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                Email
              </h6>
              <div className="relative mb-2 h-11 w-full min-w-[200px]">
                <input
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  placeholder="name@mail.com"
                  className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-900"
                />
                {errors.email && (
                  <p className="text-red-500 text-xs mt-1">{errors.email}</p>
                )}
              </div>
              <h6 className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                Şifre
              </h6>
              <div className="relative mb-2 h-11 w-full min-w-[200px]">
                <input
                  type="password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  placeholder="********"
                  className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-900"
                />
                {errors.password && (
                  <p className="text-red-500 text-xs mt-1">{errors.password}</p>
                )}
              </div>
              <h6 className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                Şifre (Tekrar)
              </h6>
              <div className="relative mb-2 h-11 w-full min-w-[200px]">
                <input
                  type="password"
                  name="passwordConfirm"
                  value={form.passwordConfirm}
                  onChange={handleChange}
                  placeholder="********"
                  className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-900"
                />
                {errors.passwordConfirm && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.passwordConfirm}
                  </p>
                )}
              </div>
            </div>
            <div className="inline-flex items-center">
              <label
                className="relative -ml-2.5 flex cursor-pointer items-center rounded-full p-3"
                htmlFor="remember"
              >
                <input
                  type="checkbox"
                  className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                  id="remember"
                />
                <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3.5 w-3.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="1"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </span>
              </label>
              <span className="font-medium transition-colors hover:text-gray-900">
                I agree to the{" "}
                <Link to={"#"} className="text-blue-500">
                  &nbsp;Terms and Conditions
                </Link>
              </span>
            </div>
            <button
              type="submit"
              className="mt-6 block w-full select-none rounded-lg bg-gray-900 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              disabled={progress}
            >
              {progress ? (
                <Spin indicator={<LoadingOutlined spin />} size="small" />
              ) : (
                "Kayıt Ol"
              )}
            </button>
            <p className="block mt-4 font-sans text-base antialiased font-normal leading-relaxed text-center text-gray-700">
              Zaten bir hesabın mı var?{" "}
              <Link to="/login" className="font-semibold text-[#5585b5]">
                &nbsp;Giriş Yap
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
