import React from "react";

const ReviewCard = ({
  name,
  profilePhoto,
  title,
  reviewDate,
  review,
  like,
  productPhoto1,
  productPhoto2,
}) => {
  return (
    <div className="max-w-screen-lg mx-auto mt-6  bg-white shadow-md rounded-lg overflow-hidden md:max-w-2xl">
      <div className="md:flex">
        <div className="w-full p-4">
          <div className="flex items-center mb-4">
            <img
              className="w-12 h-12 rounded-full mr-4"
              src={profilePhoto}
              alt="Reviewer"
            />
            <div className="text-sm">
              <p className="text-gray-900 font-bold leading-none">{name}</p>
              <p className="text-gray-600">{reviewDate}</p>
            </div>
          </div>
          <div className="flex flex-row items-center mb-2">
            <span className="text-yellow-600 flex flex-row">
              {[...Array(5)].map((_, i) => (
                <svg
                  key={i}
                  className="w-5 h-5 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 15l-5.5 3.3 1.1-6.4L.6 6.9l6.5-1L10 0l2.9 5.9 6.5 1-4.8 4.9 1.1 6.4z" />
                </svg>
              ))}
            </span>
            <p className="ml-2 text-lg font-bold">{title}</p>
          </div>
          <p className="text-orange-600 font-bold mb-2">Verified Purchase</p>
          <p className="text-gray-700 mb-4">{review}</p>
          <div className="flex">
            <img
              className="w-24 h-24 mr-2 rounded"
              src={productPhoto1}
              alt="Product"
            />
            <img
              className="w-24 h-24 mr-2 rounded"
              src={productPhoto2}
              alt="Product"
            />
          </div>
        </div>
      </div>
      <div className=" mt-4 bg-blue-50 bg-opacity-70 px-1 rounded-lg mb-2 min-w-42 flex flex-row items-center">
        <div className="bg-blue-100 p-2 rounded-full  mr-3">
          <img
            className="w-5 h-5  rounded"
            src={require("../assets/thumb-up.png")}
            alt="like"
          />
        </div>
        <p className=" text-gray-600 justify-center">
          <span className="font-bold text-blue-400">{like}</span> people found
          this helpful
        </p>
      </div>
    </div>
  );
};

export default ReviewCard;
