import React from "react";

const ProfileSection = () => {
  return (
    <div className="max-w-xl mt-7 px-3 sm:mt-0 ">
      <div className="flex items-center mb-4">
        <img
          src={require("../assets/Doctor.png")} // Replace with the actual image URL
          alt="Dr. Jeremy"
          className="w-12 h-12 rounded-full mr-4"
        />
        <div>
          <h2 className="text-lg font-bold flex items-center">
            By Leading US Doctor of Physical Therapy
            <span className="ml-2">
              <img
                className="h-5 w-5"
                src={require("../assets/mark.png")}
                alt="Verified"
              />
            </span>
          </h2>
          <p className="text-gray-600">'01/28/2024'</p>{" "}
          {/* Replace with actual date logic */}
        </div>
      </div>

      <p className="text-gray-700 text-lg font-semibold mb-4">
        Hi, my name is Dr. Jeremy and I’m a physical therapist from Chicago.
      </p>
      <p className="text-gray-700 text-lg font-semibold mb-4">
        I have 10 years of clinical experience and clocked in well over 10,000
        hours.
      </p>
      <p className="text-gray-  text-lg font-semibold mb-4">
        Throughout my career, I’ve helped over 1100 patients who came to me with
        all kinds of foot and leg problems...
      </p>

      <div className="bg-blue-100 p-4 max-w-lg rounded-lg mb-4">
        <ul className=" list-inside">
          <li className="text-black gap-2 items-center flex-row flex text-lg font-bold">
            <span className="flex-row">
              <img
                className="h-5 w-5"
                src={require("../assets/arrow.png")}
                alt=""
              />
            </span>
            Varicose veins
          </li>
          <li className="gap-2 text-black items-center flex-row flex text-lg font-bold">
            <span className="flex-row">
              <img
                className="h-5 w-5"
                src={require("../assets/arrow.png")}
                alt=""
              />
            </span>
            Edema and lymphedema
          </li>
          <li className="texblatext-black gap-2 items-center flex-row flex text-lg font-bold">
            <span className="flex-row">
              <img
                className="h-5 w-5"
                src={require("../assets/arrow.png")}
                alt=""
              />
            </span>
            Neuropathy
          </li>
          <li className="text-black gap-2 items-center flex-row flex text-lg font-bold">
            <span className="flex-row">
              <img
                className="h-5 w-5"
                src={require("../assets/arrow.png")}
                alt=""
              />
            </span>
            Plantar fasciitis
          </li>
        </ul>
      </div>

      <p className="text-gray-700 font-sans text-normal font-semibold mb-4">
        You name it.
      </p>
      <p className="text-gray- font-sans font-semibold mb-4">
        I’ve seen it all.
      </p>
      <p className="text-gray-700  font-sans font-semibold">
        From mild stiffness and swelling... To changes in skin color around a
        varicose vein... To...
      </p>
    </div>
  );
};

export default ProfileSection;
