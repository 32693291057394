import React from "react";

// Dummy comments data
const comments = [
  {
    name: "Wilma Devon",
    text: "Can anybody vouch for this?",
    time: "39 min",
    replies: [
      {
        name: "Mary Vernon",
        text: "This massager is what it says it is. It is very soothing to my legs. My varicose veins usually itch throughout the day. Since I have been using this massager... no more itching.",
        time: "16 min",
      },
    ],
  },
  {
    name: "Doris Skylar",
    text: "I bought mine for the full price and now are 50% off? That's not fair!",
    time: "51 min",
    replies: [],
  },
  {
    name: "Skyler Greig",
    text: "How long does shipping take??",
    time: "1 h",
    replies: [
      {
        name: "Marie Campbell",
        text: "Hey Skyler, got mine after a week.",
        time: "24 min",
      },
    ],
  },
  {
    name: "Leonard Boyd",
    text: "I only use it once a day but what a difference it has made! My spider veins on my ankles are barely visible and the larger vein on the back of my calf no longer bulges out. I am very impressed with this product.",
    time: "1 h",
    replies: [],
    image: require("../assets/1714666799391_1690297687472_1_1_.jpg"), // Replace with actual image path
  },
  {
    name: "Emma Emerson",
    text: "Hey Lois, this is what you need instead of the expensive creams",
    time: "2 h",
    replies: [
      {
        name: "Lois Clive",
        text: "Wow, this is crazy, have ordered one now!",
        time: "1 h",
      },
    ],
  },
  {
    name: "Alfred Johnson",
    text: "Did you buy one, how long does it take to get it",
    time: "2 h",
    replies: [
      {
        name: "Edith Ashton",
        text: "For me 7 business days.",
        time: "2 h",
      },
    ],
  },
];

const Comment = ({ name, text, time, replies = [], image }) => (
  <div className="my-4">
    <div className="flex items-start space-x-4">
      <div className="flex-shrink-0">
        <img
          src={require("../assets/user (1).png")}
          alt={name}
          className="h-10 w-10 rounded-full"
        />
      </div>
      <div>
        <div className="text-sm">
          <span className="font-medium text-gray-900">{name}</span>
          <span className="text-gray-600"> · {time}</span>
        </div>
        <p className="mt-1 text-gray-700">{text}</p>
        {image && (
          <img src={image} alt="Comment" className="mt-2 rounded-lg max-w-32" />
        )}
        <div className="flex space-x-2 text-sm text-gray-500">
          <button className="hover:underline">Like</button>
          <button className="hover:underline">Reply</button>
        </div>
        {replies.map((reply, index) => (
          <div key={index} className="ml-8 mt-4">
            <Comment {...reply} />
          </div>
        ))}
      </div>
    </div>
  </div>
);

const CommentsSection = () => {
  return (
    <div className="max-w-2xl mx-auto p-4">
      <h2 className="text-xl font-bold mb-4">Comments</h2>
      <div className="border border-gray-300 rounded-lg p-4 mb-4">
        <input
          type="text"
          placeholder="Add a comment..."
          className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
        />
      </div>
      {comments.map((comment, index) => (
        <Comment key={index} {...comment} />
      ))}
    </div>
  );
};

export default CommentsSection;
