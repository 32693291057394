import React from "react";

const CustomerComparison = () => {
  return (
    <div className="flex flex-col md:flex-row md:max-w-screen-lg lg:flex-row justify-center items-start p-4">
      {/* Successful Customer */}
      <div className="w-full md:w-1/2 p-4 border-2 bg-blue-100 px-9 border-green-500 rounded-lg m-2 flex flex-col">
        <div className="w-full flex flex-col items-center">
          <h2 className="text-center mx-auto text-2xl text-green-700 font-bold">
            SUCCESSFUL CUSTOMER
          </h2>
          <div className="grid min-h-[100px] w-full rounded-lg px-2 lg:overflow-visible">
            <div className="flex justify-center gap-2 font-bold text-blue-gray-500">
              <div className="inline-flex items-center">
                {Array(5)
                  .fill("")
                  .map((_, index) => (
                    <span key={index}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-6 h-6 text-yellow-700 cursor-pointer"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <p className="text-center text-lg mb-5 font-bold text-green-600">
          Results: High / Life-changing
        </p>
        <img
          src={require("../assets/1721900006592_1_1_.png")}
          alt="Successful Customer"
          className="w-full mb-4 max-h-96 object-contain"
        />
        <ul className="list-none space-y-6 font-semibold text-gray-700 text-lg flex-grow">
          <li className="mb-2">
            <span className="mr-3" role="img" aria-label="check">
              ✅
            </span>{" "}
            Commits to use the product on average for{" "}
            <strong className="font-bold text-black">112 days</strong>.
          </li>
          <li className="mb-2">
            <span className="mr-3" role="img" aria-label="check">
              ✅
            </span>{" "}
            Schedules a{" "}
            <strong className="font-bold text-black">
              FREE 1-on-1 onboarding
            </strong>{" "}
            with a wellness specialist within 3 days.
          </li>
          <li className="mb-2">
            <span className="mr-3" role="img" aria-label="check">
              ✅
            </span>{" "}
            Follows a personalized blueprint daily for{" "}
            <strong className="font-bold text-black">96 days</strong>.
          </li>
          <li className="mb-2">
            <span className="mr-3" role="img" aria-label="check">
              ✅
            </span>{" "}
            Completes the{" "}
            <strong className="font-bold text-black">
              30-day pain relief video program
            </strong>
            .
          </li>
          <li className="mb-2">
            <span className="mr-3" role="img" aria-label="check">
              ✅
            </span>{" "}
            Joins the NoroPulse™ VIP community for support and motivation.
          </li>
          <li className="mb-2">
            <span className="mr-3" role="img" aria-label="check">
              ✅
            </span>{" "}
            Doesn’t expect overnight results but commits to building new habits.
          </li>
        </ul>
      </div>

      {/* Unsuccessful Customer */}
      <div className="w-full md:w-1/2 p-4 border-2 bg-gray-300 border-red-500 rounded-lg m-2 flex flex-col">
        <div className="w-full flex flex-col items-center">
          <h2 className="text-center mx-auto text-2xl font-bold text-red-700 ">
            UNSUCCESSFUL CUSTOMER
          </h2>
          <div className="grid min-h-[100px] w-full rounded-lg px-2 lg:overflow-visible">
            <div className="flex justify-center gap-2 font-bold text-blue-gray-500">
              <div className="inline-flex items-center">
                {Array(2)
                  .fill("")
                  .map((_, index) => (
                    <span key={index}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-6 h-6 text-yellow-700 cursor-pointer"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  ))}
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 cursor-pointer text-blue-gray-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    ></path>
                  </svg>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 cursor-pointer text-blue-gray-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    ></path>
                  </svg>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 cursor-pointer text-blue-gray-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
        <p className="text-center mb-5 text-lg font-bold text-red-600 mb-2">
          Results: Low / Medium
        </p>
        <img
          src={require("../assets/1721900020323_2_2_.png")}
          alt="Unsuccessful Customer"
          className="w-full mb-4 max-h-96 object-contain"
        />
        <ul className="list-none space-y-6 text-lg font-semibold text-gray-700 flex-grow">
          <li className="mb-2">
            <span className="mr-3" role="img" aria-label="cross">
              ❌
            </span>{" "}
            Uses the product sporadically.
          </li>
          <li className="mb-2">
            <span className="mr-3" role="img" aria-label="cross">
              ❌
            </span>{" "}
            Doesn’t schedule the onboarding session.
          </li>
          <li className="mb-2">
            <span className="mr-3" role="img" aria-label="cross">
              ❌
            </span>{" "}
            Ignores personalized advice.
          </li>
          <li className="mb-2">
            <span className="mr-3" role="img" aria-label="cross">
              ❌
            </span>{" "}
            Doesn’t start the exercise program.
          </li>
          <li className="mb-2">
            <span className="mr-3" role="img" aria-label="cross">
              ❌
            </span>{" "}
            Avoids joining the community.
          </li>
          <li className="mb-2">
            <span className="mr-3" role="img" aria-label="cross">
              ❌
            </span>{" "}
            Complains that NoroPulse™ doesn’t work.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CustomerComparison;
