import React from "react";

const CustomerReviews = () => {
  const totalRatings = 3991;
  const ratings = {
    "5 Star": 90,
    "4 Star": 7,
    "3 Star": 2,
    "2 Star": 0,
    "1 Star": 1,
  };

  const features = {
    Price: 5.0,
    Effectiveness: 5.0,
    Comfort: 5.0,
    Quality: 4.8,
  };

  return (
    <div className="max-w-screen-md mx-auto mt-6 items-center p-4 m-1 bg-white rounded-sm shadow-sm">
      <h2 className="text-lg text-center text-black font-bold mb-2">
        Customer Reviews
      </h2>
      <div className="flex items-center mb-2">
        <span className="text-yellow-600 text-xl">★★★★★</span>
        <span className="ml-2 text-md font-semibold">4.8 out of 5</span>
      </div>
      <p className="text-gray-600 mb-4">{totalRatings} customer ratings</p>

      {Object.entries(ratings).map(([star, percentage]) => (
        <div key={star} className="flex items-center mb-2">
          <span className="text-black font-semibold w-1/4">{star}</span>
          <div className="w-3/4 bg-gray-200 rounded-full h-3">
            <div
              className="bg-yellow-700 h-3 rounded-full"
              style={{ width: `${percentage}%` }}
            ></div>
          </div>
          <span className="ml-2 font-semibold text-black">{percentage}%</span>
        </div>
      ))}

      <h3 className="text-md text-black font-bold mt-4 mb-2">By Feature</h3>
      {Object.entries(features).map(([feature, rating]) => (
        <div key={feature} className="flex items-center gap-4 mb-2">
          <span className="w-1/3">{feature}</span>
          <span className="w-2/3 text-yellow-700 font-bold">
            {"★★★★★".slice(0, rating)}
            {"☆☆☆☆☆".slice(rating)}
          </span>
          <span className="ml-2">{rating}</span>
        </div>
      ))}
    </div>
  );
};

export default CustomerReviews;
