import React from "react";
import PageComponent2 from "./PageComponent2";
import PageComponent3 from "./PageComponent3";

const PageComponent1 = () => {
  return (
    <div className="md:max-w-fit lg max-w-screen-xl mt-5 mx-auto">
      {" "}
      <div className="px-2">
        <h1 className="text-3xl xl:max-w-screen-xl font-bold mb-2">
          Varicose Veins So Swollen And Twisted, They Look Like A Nest Of Snakes
          Curled Just Beneath The Skin
        </h1>
        <img
          className="mt-8 rounded-lg w-screen-sm  lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
          src={require("../assets/1715705926240_221.jpg")}
          alt=""
        />
        <div className="pt-6 mx-auto space-y-5">
          <h2 className=" text-lg text-gray-700 font-semibold">
            But it wasn’t until earlier this year that I truly cracked the code
            to provide{" "}
            <span className=" inline mt-0 p-1 rounded-lg font-bold text-black bg-yellow-800 px-1">
              relief from varicose veins.
            </span>
          </h2>
          <p className=" text-lg text-gray-700  mt-4 font-semibold">
            It all started with a personal{" "}
            <span className="inline mt-0 p-1 rounded-lg font-bold text-black">
              Breakthrough Discovery
            </span>{" "}
            I had earlier this year.
          </p>
          <p className=" text-lg font-semibold text-gray-700 mt-4 ">
            One night I was pouring through clinical studies on Varicose Veins,
            and I stumbled across something that{" "}
            <span className="inline mt-0 p-1 rounded-lg font-bold text-black bg-yellow-800 px-1">
              shocked me.
            </span>
          </p>
          <h3 className="font-sans mb  mt-4 text-3xl font-bold text-black">
            Shocking Truth: This is #1 Hidden Culprit Behind Varicose Veins
          </h3>
          <video
            className="mt-8 w-screen-sm rounded-lg lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
            src={require("../assets/56d26131e849497e93c85fcad28f699f.mp4")}
            alt=""
            autoPlay
            loop
            muted
            playsInline
          />
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            Everyone knows blood circulation is critical for our overall health
            and well-being...
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            Even a child knows how important healthy blood circulation is.
          </p>
          <p className=" text-lg text-black  mt-6 font-bold">
            But did you know poor blood circulation is only a symptom, Not The
            Root Cause of Varicose Veins?
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            There’s{" "}
            <span className=" text-lg text-black  mt-6 font-bold">
              something else going on
            </span>{" "}
            deeper in your veins…
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            And while most people focus only on improving the blood flow…
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            They have absolutely ZERO idea of what’s really happening under the
            hood!
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            So, Veins are responsible for pushing old, stale blood from our legs
            up against gravity BACK to the heart.{" "}
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            What most people don’t know is that inside those veins, there are
            things called{" "}
            <span className="inline mt-0 p-1 rounded-lg font-bold text-black bg-yellow-800 px-1">
              Valves
            </span>{" "}
            that play a critical role in this process because{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              they push the blood up against gravity
            </span>{" "}
            and prevent it from flowing backward.
          </p>
          <h3 className="font-sans mb  mt-4 text-3xl font-bold text-black">
            These Vein Valves Act Like One-Way Gates… Allowing Blood To Flow
            Forward But{" "}
            <span className="font-sans mb underline  mt-4 text-3xl font-bold text-black">
              NEVER BACKWARD
            </span>
          </h3>
          <video
            className="mt-8 w-screen-sm rounded-lg  lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
            src={require("../assets/fb6de47998584a04a4cc5b6ec03b7c78 (1).mp4")}
            alt=""
            autoPlay
            loop
            muted
            playsInline
          />
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            That’s{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              extremely important.
            </span>{" "}
            Because as you will soon find out if the blood flows backward, it
            can cause all kinds of nasty issues.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            The picture above shows a{" "}
            <span className="inline mt-0  rounded-lg font-bold text-green-700 bg-green-100 px-1">
              healthy vein
            </span>{" "}
            on the left side vs.{" "}
            <span className="inline mt-0 rounded-lg font-bold text-red-600 bg-blue-100 px-1">
              a weakened varicose vein
            </span>{" "}
            on the right side.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            Under normal circumstance, vein valves in your calves work together
            in harmony and push the old, stale blood from our feet and calves up
            against gravity back to the heart.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            You can see in the picture on the left how the blood travels only in{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              one direction.
            </span>
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            That’s what a healthy vein with healthy vein valves looks like.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            But many of us, through no fault of our own, have the{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              Vein Valves weakened.
            </span>
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            Let’s take a look at the right side of the image.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            The first thing you will notice is that compared to the healthy
            vein, the{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              varicose vein is enlarged and swollen,
            </span>{" "}
            right?
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            <span className="text-lg text-black  mt-6 font-bold">
              That’s because as the vein valves weaken
            </span>
            , they fail to push the old blood away from our legs back to the
            heart.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            As a result,{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              the blood gets kind of stuck in the vein
            </span>{" "}
            in all kinds of places - especially behind those vein valves.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            Which leads to old, stale blood pooling in your legs.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            As that blood pools, pressure increases in the affected veins.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            This pressure{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              enlarges the veins
            </span>
            , making them BULGE.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            Much like a kinked hose where the blood cannot flow properly,{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              leading to backups, swelling, and bulging veins.
            </span>
          </p>
          <video
            className="mt-8 w-screen-sm rounded-lg  lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
            src={require("../assets/b766b5d7628b48abaf071ddf591f69d1 (1).mp4")}
            alt=""
            autoPlay
            loop
            muted
            playsInline
          />
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            Over time, this causes those ugly varicose veins that look like
            twisted ropes coiling tightly beneath your skin.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            This failure is called{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              Venous Insufficiency.
            </span>
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            And it’s{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              the real hidden culprit
            </span>{" "}
            behind varicose veins.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            Most people don’t notice they have early symptoms…
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            But it’s{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              the leading cause
            </span>{" "}
            of why we struggle with varicose veins, swelling, and heaviness
            described as having legs stuck in concrete.
          </p>
          <p className="text-lg text-black  mt-6 font-bold">
            Now Here’s The Problem…
          </p>
          <h3 className="font-sans mb  mt-4 text-3xl font-extrabold text-black">
            If We Don't Address The Root Cause, Varicose Veins Will Continue To
            Progress
          </h3>
          <img
            className="mt-8 w-screen-sm rounded-lg  lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
            src={require("../assets/1715704969327_da_1_.jpg")}
            alt=""
          />
          <p className="text-lg text-black  mt-6 font-bold">
            Over 76 million Americans million struggle with varicose veins.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            And while the majority is focusing only on improving blood flow…
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            They totally miss the fact that it’s{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              the “Venous Insufficiency” that’s the root cause.
            </span>
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            This is why popular remedies like{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              compression socks, topical creams, and different pills{" "}
              <span className="underline">can’t solve the problem.</span>
            </span>
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            They focus on blood flow and NOT on the Vein Valves.
          </p>
          <p className="text-lg text-black  mt-6 font-bold">
            So What’s The Solution?
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            Here’s a secret, not many people know but everyone should know about
            Varicose Veins…
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            The answer for improving varicose veins and relieving sollen legs…
            lies in your{" "}
            <span className="text-lg text-black  mt-6 font-bold">calves!</span>
          </p>
          <h3 className="font-sans mb  mt-4 text-3xl font-extrabold text-black">
            Did You Know{" "}
            <span className="underline">Your Calves Play A Crucial Role</span>{" "}
            In Supporting Healthy Blood Flow?
          </h3>
          <video
            className="mt-8 w-screen-sm rounded-lg  lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
            src={require("../assets/0fccce1171414b2a8776bbc38d4d449e (1).mp4")}
            alt=""
            autoPlay
            loop
            muted
            playsInline
          />
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            See… Every time you take a step your calf muscles contract which
            squeezes the vein valves in your calves.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            This compression opens up the vein valves and{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              pushes the old stale blood up in the right direction
            </span>
            , against gravity.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            That’s why we like to call calves{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              “the second heart”
            </span>{" "}
            - because they single-handedly maintain normal blood circulation in
            your lower body.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            That’s why exercising your calves and overall body is a great way to
            manage varicose veins and swelling.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            But we already know that’s impossible when your feet and legs feel
            like they’re ticking time bombs…
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            With each step closer to exploding.
          </p>
          <p className="text-lg text-black  mt-6 font-bold">
            Luckily there’s now a better way.
          </p>
          <h3 className="font-sans mb  mt-4 text-3xl font-extrabold text-black">
            Improve Your Varicose Veins And Relieve Leg Swelling at Home in Just
            15 Minutes Per Day (Without Lifting a Finger)
          </h3>
          <video
            className="mt-8 w-screen-sm rounded-lg  lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
            src={require("../assets/730a63eb89724af3b742bf7557cc4156 (1) (1).mp4")}
            alt=""
            autoPlay
            loop
            muted
            playsInline
          />
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            It's all possible thanks to something called{" "}
            <span className="inline mt-0 p-1 rounded-lg font-bold text-black bg-yellow-700 px-1">
              “NeuroMuscular Electrical Stimulation Technology”
            </span>
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            It’s a technology that uses gentle electrical stimulation to
            <span className="text-lg text-black  mt-6 font-bold">
              contract and “defibrillate” your weak calf muscles
            </span>
            .
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            This helps propel blood that’s pooled in your calves and legs
            through the veins.
          </p>
          <p className="text-lg text-black  mt-6 font-bold">
            Reducing blood pooling decreases the pressure on weakened vein
            valves, which improves their function.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            It works by emitting targeted, low-level electrical impulses at a
            proven frequency that penetrate deep into your calves.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            These impulses cause your calf muscles to alternate between
            contraction and relaxation…
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            Creating a powerful pumping effect that can enhance healthy blood
            circulation and stimulate vein valves in our calves.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            Ultimately{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              reducing varicose veins
            </span>{" "}
            and temporarily reducing swelling in your legs, calves, ankles, and
            feet.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            All without you lifting a finger.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            The only problem was that this technology wasn’t available to an
            average Joe. It was very expensive and reserved for high-end
            hospitals.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            So I partnered up with a U.S. biotech startup called NoroPulse™.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            These guys are known for making some of the best pain relief devices
            that have{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              helped more than 300,000 men and women across America.
            </span>
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            And together, we solved this problem once and for all.
          </p>

          <h3 className="font-sans mb  mt-4 text-3xl font-extrabold text-black">
            Introducing{" "}
            <span className="text-blue-900">NoroPulse™ NMES Foot Massager</span>
          </h3>
          <video
            className="mt-8 w-screen-sm rounded-lg  lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
            src={require("../assets/d485982842ae42e2bd743c3ac7892b17 (1).mp4")}
            alt=""
            autoPlay
            loop
            muted
            playsInline
          />
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            <span className="text-lg text-black  mt-6 font-bold">
              This simple, affordable, doctor-approved
            </span>{" "}
            ritual you can do at home{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              has the power to stimulate the blood circulation system in your
              entire lower body…
            </span>
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            And relieve varicose veins and stubborn swelling as if you let the
            air out of a balloon.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            It has nothing to do with existing solutions like compression socks,
            elevating your feet, using salt baths, or taking water pills.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            <span className="font-bold text-blue-800">NoroPulse™</span>{" "}
            <span className="text-lg text-blue-800  mt-6 font-bold">
              NMES Foot Massager{" "}
            </span>
            is one of the best most affordable devices that fully harnesses the
            power of the NeuroMuscular Electrical Stimulation Technology to{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              reduce varicose veins
            </span>{" "}
            and temporarily relieve swelling, stiffness, and pain in your legs,
            calves, ankles, and feet.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            It’s made from a special soft rubber designed for optimal electrical
            conductivity, enhancing effectiveness and comfort. This allows
            electrical currents to penetrate into the muscle tissue of your feet
            and legs.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            The moment you put your feet on{" "}
            <span className=" font-bold text-blue-800">NoroPulse™</span>{" "}
            <span className="text-lg text-blue-800  mt-6 font-bold">
              NMES Foot Massager
            </span>
            , you’ll immediately feel powerful electrical impulses spreading
            through your feet and legs.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            These impulses penetrate deep into your muscle tissue, mimicking
            muscle contraction.
          </p>
          <p className=" text-lg text-gray-700  mt-6 font-semibold">
            This rhythmic contraction and relaxation of muscle tissue{" "}
            <span className="text-lg text-black  mt-6 font-bold">
              pushes the pooled, stale blood up from your legs.
            </span>
          </p>
          <PageComponent2 />
          <PageComponent3 />
        </div>
      </div>
    </div>
  );
};

export default PageComponent1;
