import React from "react";
import ReviewCard from "./Review.Card";

const PageComponent2 = () => {
  return (
    <div className="md:max-w-fit  max-w-screen-xl mt-5 mx-auto">
      <h3 className="font-sans mb  mt-4 text-3xl font-extrabold text-black">
        <span className="text-blue-800 ">NoroPulse™ NMES Foot Massager</span> Is
        Unlike Any Other Solution You’ve Tried Before
      </h3>
      <video
        className="mt-8 w-screen-sm rounded-lg  lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
        src={require("../assets/f691f640e3d54ff9aff6402d816ac324 (1).mp4")}
        alt=""
        autoPlay
        loop
        muted
        playsInline
      />
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        The thin rubber foot pad was specifically designed to mimic the natural{" "}
        <span className="text-lg text-black  mt-6 font-bold">
          pumping of the venous system
        </span>{" "}
        which can simulate the muscle activity you would experience during
        activities like walking and helps open up the vein valves.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        Let me be clear. You’ve never experienced anything like{" "}
        <span className="text-blue-800 font-bold">NoroPulse™</span>{" "}
        <span className="font-bold text-blue-800">Foot Massager</span> in your
        entire life - no matter whether you think you’ve tried everything under
        the sun.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        <span className="text-lg text-black  mt-6 font-bold">
          This device is miles more powerful
        </span>{" "}
        than any wraps or compression socks ever could be because it actually
        stimulates the vein vales in your calves and{" "}
        <span className="text-lg text-black  mt-6 font-bold">
          targets the actual root cause of varicose veins and swelling.
        </span>
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        Before long, you'll be able to walk again, stand for as long as you
        want, and even sleep through the night without waking up in agony.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        It’s been{" "}
        <span className="text-lg text-black  mt-6 font-bold">
          clinically proven that NMES technology can effectively help improve
          varicose veins
        </span>{" "}
        and relieve swelling and stiffness in your thighs, calves, ankles, and
        your feet.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        But don’t take my word for it…
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        At the moment of writing this, thousands of people around the world are
        using <span className="text-blue-800">NoroPulse™</span> NMES Foot
        Massager to reduce varicose veins and relieve their swollen legs.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        Our products have{" "}
        <span className="text-lg text-black  mt-6 font-bold">
          more than 4,000 5-star reviews.
        </span>
      </p>
      <img
        className="mt-8 w-screen-sm rounded-lg  lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
        src={require("../assets/social.png")}
        alt=""
      />
      <div className="lg:flex-row lg:flex gap-4">
        <ReviewCard
          profilePhoto={require("../assets/review.jpeg")}
          name="Charlotte Harrison"
          title="It's truly worth every penny!"
          reviewDate="Reviewed in the United States on May 5, 2024"
          review="I was skeptical to buy at first because I thought Varicose Veins could only be taken care at a doctor's office or dermatologist. I have used the massager 3 times a day for the past 3 days and I notice a difference. The area is lighter and no longer raised. I am shocked! I really thought the product would do nothing. I plan to continue use and hopefully by the end of the jar they will be either gone or so light that they are not noticeable! It’s easy to use, and there are 19 intensity levels and 6 different massage types you can choose. It’s quiet when it’s running. It literally feels like you’re walking because it constantly goes from contraction to relaxation of your calves. Definitely recommend the NoroPulse NMES Foot Massager. Comes with the charging cord, charger and very good visual instructions. It’s been a lifesaver for me!"
          productPhoto1={require("../assets/17144824769131.jpg")}
          productPhoto2={require("../assets/1714483781997_3332.jpg")}
          like={76}
        />
        <ReviewCard
          profilePhoto={require("../assets/review2.jpeg")}
          name="Daniel Cooke"
          title="This works!! Blew my mind at how fast I got results"
          reviewDate="Reviewed in the United States on July 7, 2024"
          review="Wow, what a help! The BEST affordable product for varicose veins and pain in your legs. I have 2 really bad, painful vericose vein. By midday I can't stand the pain. They get so warm to the touch. I use this massager 2-3 times a day and boy does it help! The heat coming from my vein goes away, the pain is reduced and slowly they look to be going down. It's only been a couple of weeks and the difference is noticeable. I'm hoping they go away completely, but realistically I am just happy to get the relief and some more of the puffiness to go down."
          productPhoto1={require("../assets/1714483420902_344.jpg")}
          productPhoto2={require("../assets/1714556836281_4a.jpg")}
          like={104}
        />
      </div>
      <div className="flex items-center justify-center">
        <p className=" bg-yellow-700 inline-block text-lg text-center italic mt-6 font-bold">
          AS SEEN ON
        </p>
      </div>
      <img
        className="mt-8 w-screen-sm rounded-lg mb-3  lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
        src={require("../assets/1711452952776_2.jpg")}
        alt=""
      />
      <h3 className="font-sans mb  mt-7 text-3xl font-extrabold text-black">
        This Device{" "}
        <span className="underline">Has Helped Thousands of People</span> Relive
        Varicose Veins And Swelling In Their Legs, Calves, and Ankles
      </h3>
      <img
        className="mt-8 w-screen-sm rounded-lg  lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
        src={require("../assets/1715588593621_BEFORE.jpg")}
        alt=""
      />
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        And I’m confident that it’s going to help you too .
      </p>
      <p className=" text-lg  text-black  mt-6 font-bold">Just imagine…</p>
      <div className="bg-blue-100 p-4 mt-4 max-w-lg rounded-lg mb-4">
        <ul className=" list-inside">
          <li className="text-black gap-4 mb-2 items-center flex-row flex text-lg font-semibold">
            <span className="flex-row">
              <img
                className="h-5 w-10"
                src={require("../assets/close.png")}
                alt=""
              />
            </span>
            No more shame because of those unsightly varicose veins bulging like
            roots beneath your skin…
          </li>
          <li className="gap-4 text-black items-center flex-row flex text-lg font-semibold">
            <span className="flex-row">
              <img
                className="h-5 w-8"
                src={require("../assets/close.png")}
                alt=""
              />
            </span>
            No more pain and discomfort that keeps you trapped in your own body.
          </li>
        </ul>
      </div>
      <p className=" text-lg text-black  mt-6 font-bold">
        That’s all possible with the{" "}
        <span className="text-blue-800 font-bold">NoroPulse™</span>{" "}
        <span className="text-blue-800 font-bold">NMES Foot Massager.</span>
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        And the best part?
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        It only takes 15 minutes per day to notice a difference.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        All you need to do is to place your feet on the{" "}
        <span className="text-blue-800 font-bold">NoroPulse™</span> NMES Foot
        Massager... Use the buttons to select the mode and the intensity…
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        And then just sit back and relax for 15 minutes while it does its magic.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        Now, I know you probably have two main questions…
      </p>
      <h3 className="font-sans mb  mt-7 text-3xl font-extrabold text-black">
        How Can You Get Your Hands on the{" "}
        <span className="text-blue-800 ">NoroPulse™</span> NMES Foot Massager?
      </h3>
      <img
        className="mt-8 w-screen-sm rounded-lg mb-3  lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
        src={require("../assets/P+Q2QGWdq9TOgAAAABJRU5ErkJggg==.png")}
        alt=""
      />
      <p className=" text-lg text-black  mt-6 font-bold">
        …And what’s the price?
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        Well, the first question is a tricky one…
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        Because it takes us a lot of time and money to build this device…
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        From expensive parts that we have to source from all over the world… To
        countless tests that every device has to go through before it’s cleared…
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        So we are at a constant risk of{" "}
        <span className=" text-lg text-black  mt-6 font-bold">
          running out of stock.
        </span>
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        Our team is working around the clock to create enough{" "}
        <span className="text-blue-800 font-bold">NoroPulse™</span>
        <span className="font-bold text-blue-500">
          {" "}
          NMES Foot Massagers
        </span>{" "}
        for everyone in need.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        But I hate to admit that we are currently failing at that.
      </p>
      <p className=" text-lg text-black  mt-6 font-bold">
        The demand is just too big.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        Folks who have tested the Noropulse™ NMES Foot Massager and experienced
        the relief are now asking us for more devices for their families and
        friends.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        Not to mention the thousands of patients I’ve worked with in my clinic.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        They were thrilled when I told them about the device that I created and
        I’m sure that many of them are placing their orders right now.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        All this makes our stock run out quickly every time we get a new
        shipment.
      </p>
      <p className=" text-lg text-black  mt-6 font-bold">
        Now, if you are reading this article, it means that we probably have a
        few massagers left in stock, otherwise we would’ve taken the page down.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        But unfortunately, we cannot guarantee for how much longer.
      </p>
      <h3 className="font-sans mb  mt-7 text-3xl font-extrabold text-black">
        We Could Sell Out Tomorrow… Or Maybe Even Today
      </h3>
      <img
        className="mt-8 w-screen-sm rounded-lg mb-3  lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
        src={require("../assets/1711471878325_1702576968014_FTM_Pain_Relief_advertorial_v01_12.11.23_1_1_4_3_.jpg")}
        alt=""
      />
      <p className=" text-lg text-black  mt-6 font-bold">
        And once that happens…
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        Once we do run out of stock…
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        It could take us anywhere from a couple of weeks to a couple of months
        to get back in stock because these devices take quite a long time to
        build.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        So if you are serious about reducing varicose veins and relieve swelling
        in your legs, calves, and ankles…
      </p>
      <p className=" text-lg text-black  mt-6 font-bold">
        I recommend you DO NOT leave this page…
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        Because{" "}
        <span className="font-bold text-black">
          this may be your only chance
        </span>{" "}
        to get the <span className="font-bold text-blue-800">NoroPulse™</span>{" "}
        <span></span>NMES Foot Massager and experience the relief you’ve been
        praying for.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        So without further ado,{" "}
        <span className="font-bold text-black">
          let me show you how you can get your{" "}
          <span className="font-bold ">NoroPulse™</span> NMES Foot Massager
          today.
        </span>
      </p>
      <h3 className="font-sans mb  mt-7 text-3xl font-extrabold text-black">
        <span className="text-blue-800">NoroPulse™ NMES Foot Massager</span> Is
        NOT Available Anywhere Else Except on Our Official Website
      </h3>
      <img
        className="mt-8 w-screen-sm rounded-lg mb-3  lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
        src={require("../assets/ASDAS.png")}
        alt=""
      />
      <p className=" text-lg text-black  mt-6 font-bold">
        You won’t find it in retail stores. You won’t find it on Amazon or eBay.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        If you see something similar, that’s just a cheap knockoff.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        <span className="font-bold text-black">
          The only place where you can buy the real, authentic{" "}
          <span className="text-blue-800">NoroPulse™</span>{" "}
          <span className="text-blue-800">NMES Foot Massager</span> is our
          official website
        </span>{" "}
        where it's selling for just $109.99.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        Given how sophisticated the{" "}
        <span className="font-bold text-black">technology</span> behind is and
        how quickly our users experience relief, this is an absolute steal.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        To put it into perspective, when we hired business & marketing
        consultants to help us launch the device to the market,{" "}
        <span className="font-bold text-black">
          they told us to charge $400
        </span>
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        It certainly made sense from the business perspective.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        But I’m not a businessman. I’m a doctor.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        And{" "}
        <span className="font-bold text-black">
          my only goal with this device is to help as many people as possible.
        </span>
      </p>
      <h3 className="font-sans mb  mt-7 text-3xl font-extrabold text-black">
        <span className="bg-yellow-600 inline">We Priced It at 1/2</span> of
        What All the Marketing Experts Recommended
      </h3>
      <video
        className="mt-8 w-screen-sm rounded-lg mb-3  lg:max-w-screen-md xl:max-w-screen-xl 2xl:max-w-screen-xl"
        src={require("../assets/cda67009cb9f4a0daedf99b921b197be (1).mp4")}
        alt=""
        autoPlay
        loop
        muted
        playsInline
      />
      <p className=" text-lg text-black  mt-6 font-bold">Just $109.99.</p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        So even if you use it for just one year... And only once a day...
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        <span className="font-bold text-black">
          That's only .55 cents per therapy
        </span>{" "}
        which would normally cost hundreds!
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        And guess what? You'll probably use it way more than that.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        <span className="text-blue-800">NoroPulse™</span>{" "}
        <span className="font-bold text-black">
          NMES Foot Massager is built to last.
        </span>{" "}
        You'll be enjoying massages for years and years to come, as many times
        per day as you please.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        So it will end up being way less than $.55 cents per therapy.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        When you put it like this, you realize what an amazing deal this is.
      </p>
      <div className="bg-blue-100 p-4 mt-4 max-w-lg rounded-lg mb-4">
        <ul className=" list-inside">
          <li className="gap-4 text-black items-center flex-row flex text-lg font-semibold">
            <span className="flex justify-center  gap-3 flex-row">
              <img
                className="h-5 w-5"
                src={require("../assets/check.png")}
                alt=""
              />
              One investment of $109 for life without pain, swelling, and bulgy
              veins on your legs..
            </span>
          </li>
          <li className="gap-4 text-black items-center flex-row flex text-lg font-semibold">
            <span className="flex justify-center  gap-3 flex-row">
              <img
                className="h-5 w-5"
                src={require("../assets/check.png")}
                alt=""
              />
              One investment of $109 for life without pain, swelling, and bulgy
              veins on your legs..
            </span>
          </li>
          <li className="gap-4 text-black items-center flex-row flex text-lg font-semibold">
            <span className="flex justify-center  gap-3 flex-row">
              <img
                className="h-5 w-5"
                src={require("../assets/check.png")}
                alt=""
              />
              It’s a once-in-a-lifetime deal.
            </span>
          </li>
        </ul>
      </div>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        And I know you know it.{" "}
      </p>
      <p className=" text-lg text-black  mt-6 font-bold">
        But I also know that some of you simply can't afford it.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        With everything that's going on in the world...
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        Inflation running rampant...
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        Prices going up...
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        $109 is a lot of money.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        And look... I didn't lie when I said that{" "}
        <span className="font-bold text-black">
          this isn't about money for me.
        </span>
      </p>
      <p className=" text-lg text-black  mt-6 font-bold">
        I want this thing to help as many people as possible.
      </p>
      <p className=" text-lg text-black  mt-6 font-bold">
        And I don't want money to stand in the way of that mission.
      </p>
      <p className=" text-lg text-gray-700  mt-6 font-semibold">
        So to ensure that everyone in need has a chance to put their hands on{" "}
        <span className="text-blue-800">NoroPulse™</span> NMES Foot Massager...
      </p>
    </div>
  );
};

export default PageComponent2;
